import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import brand1 from "../../assets/Images/brand1.png";
import brand2 from "../../assets/Images/brand2.png";
import brand3 from "../../assets/Images/brand3.png";
import brand4 from "../../assets/Images/brand4.png";
import brand5 from "../../assets/Images/brand5.png";
import brand6 from "../../assets/Images/brand6.png";
import brand7 from "../../assets/Images/brand7.png";
import brand8 from "../../assets/Images/brand8.png";
import brand9 from "../../assets/Images/brand9.png";

export default function ApprovalsBanners() {

    const [slidesToShow, setSlidesToShow] = useState(5);
    const [screenWidth, setScreenWidth] = useState();

    useEffect(() => {
        const handleResize = () => {
            // Adjust slidesToShow based on screen size
            if (window.innerWidth < 768) {
                setSlidesToShow(1);
            } else if (window.innerWidth < 992) {
                setSlidesToShow(3);
            } else {
                setSlidesToShow(5);
            }
            setScreenWidth(window.innerWidth);
        };

        // Call handleResize initially and add event listener
        handleResize();
        window.addEventListener("resize", handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        accessibility: false,
        centerMode: true,
        // variableWidth: true,
        className: "brand-slider",
        // centerPadding: "10px",
    };

    const HomeSlider = [
        // {image: brand1},
        { image: brand2 },
        { image: brand3 },
        { image: brand4 },
        { image: brand5 },
        { image: brand6 },
        { image: brand7 },
        { image: brand8 },
        { image: brand9 },
    ];

    return (
        <div>
            <h4 className="product-heading heading text-center fw-bold" style={{ color: "#004F87" }}>Approvals</h4>
            <Slider {...settings}>
                {HomeSlider.map((item, i) => {
                    return (
                        <div key={i} className="w-auto">
                            <img src={item.image} width={"100%"} style={{ padding: "0px 5px" }} alt={`home slider ${i}`} />
                        </div>
                    )
                })}
            </Slider>
            <style>
                {`
                .slick-next {
                    right: 15px;
                    z-index: 999;
                }
                .slick-next:before{
                    color: #dba63a;
                }
                .slick-prev {
                    left: 15px;
                    z-index: 999;
                }
                .slick-prev:before{
                    color: #dba63a;
                }
                `}
            </style>
        </div>
    )
}
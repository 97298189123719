import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';

export default function InvestorContact() {
    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Investor Contact</h2>
                    <div>
                    <div className="invertors-heading mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"24px", fontSize:"20px"}}>
                        Divya A - Company Secretary <br />
                        Phone - 9895863515 <br />
                        Mail Id - cs@balcopipes.com
                    </div>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}

// Investor tab:
// b)    Financial results, please add a tab within to upload a Pdf doc.
// c)    Annual Report, please add a tab within to upload a Pdf doc.
// d)    Annual Return, please add a tab within to upload a Pdf doc.

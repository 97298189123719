import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Dealer.css';
import dealerImage from "../../assets/Image content/dealer.png";
import React, { useEffect, useState } from 'react';
import axios from 'axios';





export default function Dealer() {
    const [dealerInputs, setDealerInputs] = useState({
        dealername: "",
        dealeremail: "",
        dealerphone: "",
        dealertype: "",
        dealercommand: ""
    });


    const [searchData, setSearchData] = useState({
        dealer: '',
        sector: '',
        state: '',
        district: '',
        pincode: ''
    });

    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [stateList, setStateList] = useState(null);
    const [districtsList, setDistrictsList] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('https://admin.balcopipes.in/dealers/list-states');
                setStateList(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
        // fetchSearchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`https://admin.balcopipes.in/dealers/list-districts/${searchData.state}`);
                setDistrictsList(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (searchData.state) {
            fetchData();
        }
    }, [searchData.state]);

    // https://admin.balcopipes.in/dealers/search
    // type:1 (distributors - 1, dealers - 2)
    // sector:1 (electrical - 1 , water pipe - 2)
    // state:16 (state id)
    // district:301 ( district id)
    // pin: A s k (this is general value)

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`https://admin.balcopipes.in/dealers/list-districts/${searchData.state}`);
                setDistrictsList(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        if (searchData.state) {
            fetchData();
        }
    }, [searchData.state]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchData({
            ...searchData,
            [name]: value
        });
    };

        const fetchSearchData = async () => {
            console.log("'type', searchData.dealer: ", searchData.dealer)
            try {
              setIsLoading(true);
        
              const formData = new FormData();
              formData.append('type', searchData.dealer);
              formData.append('sector', searchData.sector);
              formData.append('state', searchData.state);
              formData.append('district', searchData.district);
              formData.append('pin', searchData.pincode);
        
              const response = await axios.post('https://admin.balcopipes.in/dealers/search', formData);
        
              setTimeout(() => {
                setIsLoading(false);
        
                if (response.data === 'No data found') {
                  setSearchResults([]);
                } else {
                  const dealerData = response.data.filter(item => item.address);
                  setSearchResults(dealerData);
                }
              }, 1000); // 1 seconds delay
            } catch (error) {
              console.error('Error fetching data:', error);
              setIsLoading(false);
            }
          };


    const handleSubmit = (e) => {
        e.preventDefault();
        if(searchData){
            fetchSearchData();
        }
        console.log('Search Data:', searchData);
    };


      const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        type: ''
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDealerInputs({
          ...dealerInputs,
          [name]: value
        });
        // Clear the corresponding error when user starts typing
        setErrors({
          ...errors,
          [name]: ''
        });
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!dealerInputs.dealername.trim()) {
          newErrors.name = 'Name is required';
          isValid = false;
        }
    
        if (!dealerInputs.dealeremail.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
        }

        if (!dealerInputs.dealertype) {
            newErrors.type = 'Type is required';
            isValid = false;
          }
    
        if (!dealerInputs.dealerphone.trim()) {
          newErrors.phone = 'Phone number is required';
          isValid = false;
        } else if (!/^\d{10}$/.test(dealerInputs.dealerphone.trim())) {
          newErrors.phone = 'Invalid phone number (10 digits required)';
          isValid = false;
        }
    
        setErrors(newErrors);
        return isValid;
      };
    
      const handleDealerSubmit = () => {
        if (validateForm()) {
            const { dealername, dealeremail, dealerphone, dealertype, dealercommand } = dealerInputs;
            const mailtoLink = `mailto:shareddrive@balcopipes.in?cc=${dealeremail}&subject=Contact Form Submission&body=Name: ${dealername}%0AEmail: ${dealeremail}%0APhone: ${dealerphone}%0AType: ${dealertype}%0AMessage: ${dealercommand}`;
            window.location.href = mailtoLink;
        }
      };

      const [openModalIndex, setOpenModalIndex] = useState(null);

      const toggleModal = (index) => {
          setOpenModalIndex(openModalIndex === index ? null : index);
          if (openModalIndex === null) {
              document.body.style.overflow = 'hidden';
          } else {
              document.body.style.overflow = 'auto';
          }
      };

    function isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (_) {
          return false;  
        }
      }

    const DealerModal = ({name,address,phone,location}) => {
        return (
            <div className="modal" role="dialog" style={{ display: 'block', zIndex: '9999' }}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{display:'flex', justifyContent:'space-between', alignItems:'center',padding:"5px 15px"}}>
                            <h5 className="dealer-heading heading mb-0">Dealer Details</h5>
                            <button type="button" className="btn btn-none close" onClick={() => toggleModal(null)}>
                            <i className="bi bi-x-lg" style={{color:'red', fontSize:"20px", fontWeight:"bold"}} ></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 d-flex">
                                    <div className="p-2 flex-grow-1">
                                        <div>
                                            <h4 className="dealer-text text"><span className="fw-bold">NAME: </span>{name && name}</h4>
                                            {/* <div className="dealer-text text"> */}
                                                <p className="dealer-text text mb-1"><span className="fw-bold">ADDRESS: </span>{address && address}</p>
                                                <p className="dealer-text text mt-2"><span className="fw-bold">PHONE: </span><span style={{ textDecoration: "underline" }}>{phone && phone}</span></p>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex">
                                    <div className="p-2 flex-grow-1">
                                        {location && isValidUrl(location) ? (
                                            <iframe
                                                title="dealer_location"
                                                width="100%"
                                                height="350"
                                                style={{ border: 0 }}
                                                loading="lazy"
                                                allowFullScreen
                                                referrerPolicy="no-referrer-when-downgrade"
                                                src={location}
                                            ></iframe>
                                        ) : (
                                            <p className="text-center fw-bold">Map location not found</p>
                                        )}
                                        {/* <iframe width="100%" height="350" style={{border:0}} loading="lazy" allowFullScreen referrerpolicy="no-referrer-when-downgrade" src={location}></iframe> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const isAnyFieldFilled = () => {
        return Object.values(searchData).some(field => field.trim() !== '');
      };

    return (
        <div>
            <Navbar />
            <div style={{ background: "#ECECEC" }}>
                {/* Dealer page */}
                <div className="container">
                    <div className="row py-5">
                        <div className="col-lg-6">
                            <div>
                                <h2 className="dealer-title title">Find Your BALCO Dealer!</h2>
                                <h4 className="dealer-heading heading">Building a Strong and Reliable Future Together</h4>
                                <p className="dealer-text subheading">Our extensive network of BALCO dealers spans across India, ensuring convenient access to our premium products nationwide. Easily locate your nearest dealer or distributor by utilizing our user-friendly interface. Simply navigate to the dropdown selection bar, choose whether you're seeking a distributor or dealer, then select your desired state, district, or pin. It's now simpler than ever to connect with our trusted partners. Together, let's forge a future built on strength and reliability.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={dealerImage} alt="dealerImage" width={'100%'} />
                        </div>
                    </div>
                </div>
                {/* input search */}
                <div className="container my-5">
                    <form onSubmit={handleSubmit}>
                        <div className="d-sm-flex justify-content-center flex-wrap">
                            <div className="flex-grow-1 mx-1 my-1">
                                <select name="dealer" className="dealer-inputs" defaultValue={''} onChange={handleChange}>
                                    <option value="" disabled>Dealer type</option>
                                    <option value="2">Dealer</option>
                                    <option value="1">Distributor</option>
                                </select>
                            </div>
                            <div className="flex-grow-1 mx-1 my-1">
                                <select name="sector" className="dealer-inputs" defaultValue={''} onChange={handleChange}>
                                    <option value="" disabled>Sector</option>
                                    <option value="1">Electrical</option>
                                    <option value="2">Water pipe</option>
                                </select>
                            </div>
                            <div className="flex-grow-1 mx-1 my-1">
                                <select name="state" className="dealer-inputs" defaultValue={''} onChange={handleChange}>
                                    <option value="" disabled>Select State</option>
                                    {stateList && stateList.map((item, index) => {
                                        return (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="flex-grow-1 mx-1 my-1">
                                <select name="district" className="dealer-inputs" defaultValue={''} onChange={handleChange}>
                                    <option value="" disabled>Select District</option>
                                    {searchData.state && districtsList && districtsList.map((item, index) => {
                                        return (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="flex-grow-1 mx-1 my-1">
                                <input
                                    className="dealer-inputs"
                                    type="text"
                                    name="pincode"
                                    placeholder="PIN code or location"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex-grow-1 mx-1 my-1">
                                <button type="submit" className="btn btn-gold" style={{ borderRadius: "44px", padding: "9.5px 15px" }}><i className="bi bi-search me-2"></i> Search</button>
                            </div>
                        </div>
                    </form>

                </div>
                {/* location box */}
                <div className="container my-5">
                    <div className="text-center py-3">
                        <h2 className="dealer-heading heading text-dark text-center" style={{ borderBottom: "1px solid black" }}>Results</h2>
                    </div>
                    <div className="row">
                        {isLoading ? (
                            <div className="p-5 bg-white" style={{borderRadius:"15px"}}>
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div className="row">
                            {searchResults.length > 0 ? searchResults.map((item, index) => {
                            return (
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex flex-column justify-content-between my-2" key={index}>
                                    <div className="dealer-card flex-grow-1" style={{ alignItems: "space-between" }}>
                                        <h4 className="subheading">{item.name && item.name}</h4>
                                        <div className="dealer-text text">
                                            <p>{item.address && item.address}</p>
                                            <p><span style={{ textDecoration: "underline" }}>{item.phone && item.phone}</span></p>
                                        </div>
                                        
                                    </div>
                                    <button className="btn btn-blue" onClick={() => toggleModal(index)}><i className="bi bi-geo-alt me-2"></i> View Location</button>
                                        {openModalIndex === index &&
                                        <DealerModal 
                                            name = {item.name}
                                            address = {item.address}
                                            phone = {item.phone}
                                            location = {item.location}
                                        />}
                                </div>
                            )
                        })
                        :
                        <div className="col-12 text-center">
                            <div className="p-5 bg-white" style={{borderRadius:"15px"}}>
                                {isAnyFieldFilled() ? (
                                    <h4 className="dealer-heading heading text-center">Data not Found!</h4>
                                    ) : (
                                    <h4 className="dealer-heading heading text-center">Search Location</h4>
                                )}
                            </div>
                        </div>
                        }
                            </div>
                        )}
                    </div>
                </div>
                {/* input box */}
                <div className="container py-5">
                    <div className="my-3">
                        <h2 className="dealer-title title text-dark text-center">Update  Dealer  & Distribution details</h2>
                    </div>
                    <div className="bg-white p-4" style={{ backgroundColor: "#ffffff" }}>
                        <div className="row my-2">
                            <div className="col-md-6 my-2">
                                <div className="form-group row">
                                    <label htmlFor="your-name-dealer" className="col-sm-5 col-form-label">Your Name:</label>
                                    <div className="col-sm-7">
                                        <input type="text" name="dealername" id="your-name-dealer" value={dealerInputs.dealername} onChange={handleInputChange} className="form-control" />
                                        {errors.name && <span style={{ color: 'red' }} className="m-2">{errors.name}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-2">
                                <div className="form-group row">
                                    <label htmlFor="your-email-dealer" className="col-sm-5 col-form-label">Your Email:</label>
                                    <div className="col-sm-7">
                                        <input type="email" name="dealeremail" id="your-email-dealer" value={dealerInputs.dealeremail} onChange={handleInputChange} className="form-control" />
                                        {errors.email && <span style={{ color: 'red' }} className="m-2">{errors.email}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-md-6 my-2">
                                <div className="form-group row">
                                    <label htmlFor="your-number-dealer" className="col-sm-5 col-form-label">Your Number:</label>
                                    <div className="col-sm-7">
                                        <input type="tel" name="dealerphone" id="your-number-dealer" value={dealerInputs.dealerphone} onChange={handleInputChange} className="form-control" />
                                        {errors.phone && <span style={{ color: 'red' }} className="m-2">{errors.phone}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-2">
                                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                <label>
                                    Distributor
                                    <input type="radio" name="dealertype" value="distributor" onChange={handleInputChange} className="ms-2" />
                                </label>

                                <label>
                                    Dealer
                                    <input type="radio" name="dealertype" value="dealer" onChange={handleInputChange} className="ms-2" />
                                </label>

                                <label>
                                    Orders
                                    <input type="radio" name="dealertype" value="orders" onChange={handleInputChange} className="ms-2" />
                                </label>
                                </div>
                                
                                {errors.type && <span style={{ color: 'red' }} className="m-2">{errors.type}</span>}
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-12">
                                <textarea name="dealercommand" id="dealer-command" className="form-control" rows="8" placeholder="Your Message:*" value={dealerInputs.dealercommand} onChange={handleInputChange}></textarea>
                            </div>
                        </div>
                        <div className="py-3 text-center">
                            <button className="btn btn-gold" type="button" onClick={handleDealerSubmit} style={{ color: "#004779" }}><span className="btn-square"></span> Submit Now <i className="bi bi-arrow-up-right ms-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Contact.css';
import emailIcon from "../../assets/Icons/blog-email.png";
import locationIcon from "../../assets/Icons/blog-location.png";
import phoneIcon from "../../assets/Icons/blog-phone.png";
import mapIcon from "../../assets/Icons/locationMap.png";

import socialicon1 from "../../assets/Icons/blue social_facebook_circle.png";
import socialicon2 from "../../assets/Icons/blue social_twitter_circle.png";
import socialicon3 from "../../assets/Icons/bule social_linkedin_circle.png";
import socialicon4 from "../../assets/Icons/blue social_Instagrame_circle.png";
import socialicon5 from "../../assets/Icons/blue social_youtube_circle.png";
import { useState } from "react";
import ZohoForm from "../ZohoForm";

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    
      const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        // Clear the corresponding error when user starts typing
        setErrors({
          ...errors,
          [name]: ''
        });
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
          isValid = false;
        }
    
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
        }
    
        if (!formData.phone.trim()) {
          newErrors.phone = 'Phone number is required';
          isValid = false;
        } else if (!/^\d{10}$/.test(formData.phone.trim())) {
          newErrors.phone = 'Invalid phone number (10 digits required)';
          isValid = false;
        }
    
        setErrors(newErrors);
        return isValid;
      };
    
      const handleSubmit = () => {
        if (validateForm()) {
            const { name, email, phone, message } = formData;
            const mailtoLink = `mailto:info@balcopipes.com?cc=${email}&subject=Contact Form Submission&body=Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
            window.location.href = mailtoLink;
        }
      };
    return(
        <div style={{background: '#ECECEC'}}>
            <Navbar/>
            <div className="container mb-5">
                <div className="row py-5">
                    <div className="col-lg-6" style={{background:"white"}}>
                        <ZohoForm/>
                        {/* <div className="m-sm-4" style={{border:"1px solid #00000026"}}>
                            <div className="p-5">
                                <h1 className="title fw-bold">SEND <br />US MESSAGE!</h1>
                                <div className="row py-3">
                                    <div className="col-md-6">
                                        <input type="text" name="name" value={formData.name} onChange={handleChange}  className="m-2" placeholder="Your Name:*"/>
                                        {errors.name && <span style={{ color: 'red' }} className="m-2">{errors.name}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="m-2" placeholder="Your EmailID:*"/>
                                        {errors.email && <span style={{ color: 'red' }} className="m-2">{errors.email}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className="m-2" placeholder="Your Phone:*"/>
                                        {errors.phone && <span style={{ color: 'red' }} className="m-2">{errors.phone}</span>}
                                    </div>
                                    <div className="col-12">
                                        <textarea className="m-2 w-75" rows="4" name="message" value={formData.message} onChange={handleChange} placeholder="Your Message:*"></textarea>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-gold" type="button" onClick={handleSubmit}><span className="btn-square"></span> Submit Now <i className="bi bi-arrow-up-right ms-2"></i></button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-lg-6">
                        <div className="m-sm-4">
                            <div className="p-5">
                                <span className="contact-text text" style={{color:"#004779", fontWeight:"bold"}}>Contact us</span>
                                <div className="d-flex " style={{alignItems:"center"}}>
                                <div style={{width:"10px", height:"10px",borderRadius:"50%", border: "3px solid #004779"}}></div>
                                <div style={{width:"50px",borderBottom:"1px solid #004779"}}></div>
                                </div>
                                <h2 className="contact-heading heading" style={{color:"black"}}>Have any questions? <br /> <span className="contact-heading">Get In Touch!</span></h2>
                                <p className="contact-text text">Do you have any questions or inquiries? Get in touch with us! We're here to assist you with any information you need.</p>
                                <div>
                                    <div className="contact-subheading subheading"><img src={phoneIcon} alt="phoneIcon" /> Phone: <span className="text-black">+91 6235350000</span></div>
                                    <div className="contact-subheading subheading"><img src={phoneIcon} alt="phoneIcon" /> Land Line: <span className="text-black">0475-2223867</span></div>
                                    <div className="contact-subheading subheading"><img src={emailIcon} alt="emailIcon" /> Email: <span className="text-black">info@balcopipes.com</span></div>
                                    <div className="contact-subheading subheading"><img src={locationIcon} alt="locationIcon" /> Location: <span className="text-black">Door No XIII/690/Abc Tholicode Punalur, Kollam, Kerala, India, 691333</span></div>
                                    <div className="contact-subheading subheading"><img src={mapIcon} alt="mapIcon" width={35}/> Corporate Address: <span className="text-black">2nd Floor, BALCO Building, XXIX/456, Powerhouse Ward, Tholicode PO, Punalur, Kollam, Kerala, 691333</span></div>
                                </div>
                                <div className='d-flex justify-content-start align-items-center mt-4'>
                                    <a href="https://www.facebook.com/balcopvcpipes/" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={socialicon1} alt="FaceBook" width={35} height={35}/></a>
                                        <a href="https://www.youtube.com/channel/UC1yGWVTNMK46wn2M5GZPRrA" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={socialicon5} alt="Youtube" width={35} height={35}/></a>
                                        <a href="https://www.instagram.com/balcopvcpipes/" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={socialicon4} alt="Instagram" width={35} height={35}/></a>
                                        <a href="https://www.twitter.com/balcopvcpipes" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={socialicon2} alt="Twitter" width={35} height={35}/></a>
                                        <a href="https://www.linkedin.com/company/balcopipes" target='_blank' rel="noopener noreferrer" className='mx-2' ><img src={socialicon3} alt="linkedin" width={35} height={35}/></a>

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5">
                    <p className="contact-subheading subheading text-black">Drop in our office</p>
                    <hr />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="contact-heading subheading text-black">
                                <img src={mapIcon} alt="mapIcon" width={35}/>
                                Head Office Punalur
                            </div>
                            <p className="contact-text text">Solve Plastic Products  Limited, BALCO Buildings, Near Krishnan Kovil, Tholicode P O, Punalur,Kollam (Dist),Kerala - 691333</p>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-heading subheading text-black">
                                <img src={mapIcon} alt="mapIcon" width={35}/>
                                Punalur Manufacturing plant
                            </div>
                            <p className="contact-text text">Solve Plastic Products Ltd. 2nd Floor, Balco Building, XXIX/456, Powerhouse Ward, Tholicode P.O, Punalur, Kollam - 691333</p>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-heading subheading text-black">
                                <img src={mapIcon} alt="mapIcon" width={35}/>
                                Shencottai Manufacturing plant
                            </div>
                            <p className="contact-text text">Solve Plastic Products Ltd.  3/12/82, Kesavapuram, Therkkumedu Road, Karkudy village, Puliyara PO, Shencottai - 627813</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="contact-heading subheading text-black">
                                <img src={mapIcon} alt="mapIcon" width={35}/>
                                Kannur Manufacturing plant
                            </div>
                            <p className="contact-text text">Solve Plastic Products Ltd. 14/4/4E, Nadukani, Kinfra Textile Centre, Thaliparamba, Kannur, Kerala - 670142</p>
                        </div>
                        <div className="col-sm-4">
                            <div className="contact-heading subheading text-black">
                                <img src={mapIcon} alt="mapIcon" width={35}/>
                                Edamon Manufacturing plant
                            </div>
                            <p className="contact-text text">Solve Plastic Products Limited,  TP/13/381, 384, Edamon, Kerala - 691307</p>
                        </div>
                    </div>
                </div>
                <div className="w-100">
                    {/* <img src={locationMap} alt="locationMap" width={'100%'}/> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.541458789451!2d76.92632561427645!3d9.014270591702715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06769a1659d54b%3A0xb53d17549cba7879!2sBALCO+Group!5e0!3m2!1sen!2sin!4v1563449572062!5m2!1sen!2sin" title="balco_location" width="100%" height="450" frameBorder="0" style={{border:"0"}} allowFullScreen=""></iframe>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './Investors.css';

export default function InvestorGrievances() {
    return(
        <div>
            <Navbar/>
            <div style={{backgroundColor:"#ECECEC"}}>
                <div className="container py-5">
                    <h2 className="invertors-title title text-center">Investor Grievances</h2>
                    <div>
                    <div className="invertors-heading mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"24px", fontSize:"20px"}}>
                        Shankar Sudheer Kumar - Member - Stakeholder Relationship Committee <br />
                        Phone - 8547171424 <br />
                        Mail Id - shankar@balcopipes.com
                    </div>
                    <div className="invertors-heading mt-3" style={{backgroundColor:"#fff", textAlign:"center", padding:"24px", fontSize:"20px"}}>
                        Divya A - Company Secretary <br />
                        Phone - 9895863515 <br />
                        Mail Id - cs@balcopipes.com
                    </div>
                    </div>
                </div>
                {/* <InvestorLink/> */}
            </div>
            <Footer/>
        </div>
    )
}

// Shankar Sudheer Kumar – Member- Stakeholder Relationship Committee
//  Phone: 8547171424
//  Mail Id: shankar@balcopipes.com
// Divya A – Secretary – SRC
//  Phone - 9895863515
//  Mail Id- cs@balcopipes.com
import LazyLoad from 'react-lazyload';
import Footer from '../navbar/Footer';
import Navbar from '../navbar/Navbar';
import images from './Gallery';
import "./Gallery.css";


export default function Gallery() {
  return (
    <section>
    <Navbar />
    <div className="gallery-container">
      {images.map((image, index) => (
        <LazyLoad height={200} offset={100} key={index}>
        <div className="gallery-item">
          <img src={image} alt={`Gallery item ${index + 1}`} />
        </div>
      </LazyLoad>
      ))}
    </div>
    <Footer/>
    </section>
    
  );
};

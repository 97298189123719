import Navbar from "../navbar/Navbar";
import Footer from "../navbar/Footer";
import './product.css';
import allprodutsimage from '../../assets/Images/overall-products.png';
import waterTanksImage from '../../assets/Images/Tank product.png';
import waterTanksImage1 from '../../assets/Images/Tank product 1.png';
import waterTanksImage2 from '../../assets/Images/Tank product 2.png';
import waterTanksImage3 from '../../assets/Images/Tank product 3.png';
import watermark from '../../assets/Images/Water tank waterMark.png';


export default function WaterTanks () {
    return(
        <div>
            <Navbar/>
            <div className="">
                {/* slider head */}
                <div style={{background: "#D9D9D9"}}>
                    <div className="container">
                    <div className="d-md-flex justify-content-center aline-item-center align-items-center my-5">
                        <div className="">
                            <h2 className="product-title title">Your Long-Term Solution for Safe and Reliable Water Storage!</h2>
                        </div>
                        <div className="">
                            <img src={waterTanksImage} alt="waterTanksImage" width={"100%"}/>
                        </div>
                    </div>
                    <div>
                    <img src={watermark} alt="watermark" width={"100%"}/>
                    </div>
                    </div>
                </div>
                {/* product type */}
                <div className="container">
                <div className="row my-4">
                    <div className="col-md-6">
                        <h2 className="product-heading heading">Water Tank</h2>
                        <p className="product-text text">We understand the importance of clean and pure water for your daily needs. That's why we are proud to present our range of high-quality wat  er tanks, designed to keep your water safe and pure for an extended period. We prioritize your well-being and have carefully chosen materials that do not pose any harm to your health. Our water tanks are built with precision and expertise, offering exceptional quality and reliability. With a focus on durability and safety, we ensure that your water remains pure and untainted. Our water tanks are engineered to be algae-free, preventing the growth of harmful microorganisms. The advanced anti-algae technology keeps your water fresh and clean. Additionally, our tanks are corrosion-resistant, ensuring the longevity of the tank even in harsh environments.  you can trust that your water will remain at an optimal temperature regardless of the climate. Our tanks are designed to withstand extreme heat, ensuring that the stored water remains cool and refreshing. we prioritize your health and well-being. Our water tanks have undergone rigorous testing to ensure they meet the highest safety standards. We use materials that are free from any harmful substances, guaranteeing that the water stored in our tanks remains safe for consumption.</p>
                        <p className="product-subheading subheading">Features</p>
                            <ul className="product-text text">
                                <li>High quality</li>
                                <li>Reduced fumes and odour during installation</li>
                                <li>Meet the required standards for solvent cement used for plastic pipe installation.</li>
                                <li>Useful in piping applications like consumable water, turfs, water systems, pools and spas, fire assurance, conductor, and sewers.</li>
                            </ul>
                    </div>
                    <div className="col-md-6">
                        <div className="m-4">
                            <img src={waterTanksImage1} alt="waterTanksImage1" width={"100%"}/>
                        </div>
                    </div>
                </div>
                {/* <div className="row my-4 text-center">
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                    <div className="col-md-3 col-sm-6 my-2">
                        <button className="btn btn-gray"><span className="p-4 product-subheading subheading">Certifications</span></button>
                    </div>
                </div> */}
                </div>
                {/* product heading and color */}
                <div className="" style={{background: "#004F87"}}>
                    <div className="container text-center">
                        <div className="row py-4">
                            <div className="col-md-4 col-sm-6"> 
                                <img src={waterTanksImage1} alt="waterTanksImage1"  width={'100%'}/>
                            </div>
                            <div className="col-md-4 col-sm-6"> 
                                <img src={waterTanksImage2} alt="waterTanksImage2" width={'100%'}/>
                            </div>
                            <div className="col-md-4 col-sm-6"> 
                                <img src={waterTanksImage3} alt="waterTanksImage3" width={'100%'}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* last part */}
                <div className="py-5" style={{backgroundColor:"#004F87"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-white">
                            <p className="product-subheading subheading">Quality Analysis</p>
                            <ul className="product-text text text-white">
                                <li>Marking</li>
                                <li>Checking of Dimensions</li>
                                <li>Resistance to Heat</li>
                                <li>Resistance to Burn</li>
                                <li>Moisture Absorption Test</li>
                                <li>Resistance to Chemical Action</li>
                                <li>Copper Test</li>
                                <li>Resistance to Oil</li>
                                <li>Resistance to Impact</li>
                                <li>Electrical Strength</li>
                                <li>Insulation Resistance</li>
                            </ul>
                            </div>
                            <div className="col-md-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <div className="" >
                                        <img src={allprodutsimage} alt="allprodutsimage" width={"100%"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
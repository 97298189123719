import Header from "./Header"
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/Balco Logo.png'
import './Header.css';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useState, useEffect } from "react";
import axios from 'axios';

const drawerWidth = 240;

export default function Navbar() {

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsScrolled(scrollTop > 45);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);


  const navItems = [
    { title: 'Home', linkto: "/" },
    { title: 'About', linkto: "/about" },
    { title: 'Products', linkto: "/product" },
    { title: 'Dealers Network', linkto: "/dealer-networks" },
    { title: 'Investors', linkto: "/investors" },
    { title: 'Blog', linkto: "/blog" },
    { title: 'Gallery', linkto: "/gallery" },
    { title: 'Career', linkto: "/career" },
    { title: 'Contact', linkto: "/contact" },
  ];

  const InvestorsLinks = [
    { title: 'Board of Directors', linkto: "/investors/board-of-directors" },
    { title: 'Committee', linkto: "/investors/committee" },
    { title: 'Investors Contact', linkto: "/investors/invertors-contact" },
    { title: 'Financial Results', linkto: "/investors/financial-result" },
    { title: 'Shareholding Patterns', linkto: "/investors/share-holders-pattern" },
    { title: 'Policies', linkto: "/investors/policies" },
    { title: 'Annual Report', linkto: "/investors/annual-report" },
    { title: 'Initial Public Offer', linkto: "/investors/no-data" },
    { title: 'Annual Returns', linkto: "/investors/annual-return" },
    { title: 'Investors Grievances', linkto: "/investors/investors-grievances" },
    { title: 'Announcements', linkto: "/investors/no-data" },
    // { title: 'Disclosures Under Regulation 46 of SEBI (LODR) Regulations, 2015', linkto: "/investors/disclosures-under-regulation" },
    { title: 'Press Release', linkto: "/investors/press-release" },
  ]

  const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            const response = await axios.get('https://admin.balcopipes.in/investors/list-initial-public-offers');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleDownload = (pdfUrl, fileName) => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  

  const Preissuedadvertisement = [
    { title: 'Board Meetings', linkto: "/investors/board-meeting" },
    { title: 'General Meetings', linkto: "/investors/general-meeting" },
    { title: 'Compilances', linkto: "/investors/complainces" },
    { title: 'Other Announcements', linkto: "/investors/other-announcements" },
  ]

  const ProductsLinks = [
    { title: 'Rigid PVC electric conduits', linkto: "/product/pvc-electricals" },
    { title: 'Rigid PVC electric conduits fittings', linkto: "/product/pvc-electricals-fitting" },
    { title: 'UPVC pipes', linkto: "/product/pvc-water-pipes" },
    { title: 'UPVC pipes fitting', linkto: "/product/pvc-water-pipes-fitting" }, // /product/pvc-water-pipes-fitting (Page is Already Created)
    { title: 'Flexible conduits', linkto: "/product/flexible-hoses" },
    { title: 'Solvent cement', linkto: "/product/solvent" },
    { title: 'Green hoses', linkto: "/product/green-hoses" },
    { title: 'Water tank', linkto: "/product/water-tanks" },
  ]

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false); // || pathname.includes('investors')

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isSubDropdownOpen, setIsSubDropdownOpen] = React.useState(false);
  const [isadvertisementDropdownOpen, setIsadvertisementDropdownOpen] = React.useState(false);

  const toggleSubDropdown = () => {
    setIsSubDropdownOpen(!isSubDropdownOpen);
    setIsadvertisementDropdownOpen(false);
  };

  const toggleadvertisementDropdown = () => {
    setIsadvertisementDropdownOpen(!isadvertisementDropdownOpen);
    setIsSubDropdownOpen(false)
  };


  const [isProductDropdownOpen, setIsProductDropdownOpen] = React.useState(false); // || pathname.includes('product')

  const productToggleDropdown = () => {
    setIsProductDropdownOpen(!isProductDropdownOpen);
  };

  React.useEffect(() => {
    if (mobileOpen) {
      document.body.style.paddingRight = '0px';
    }
  }, [mobileOpen]);

  const [mobileViewProducts, setMobileViewProducts] = React.useState(false || pathname.includes('product'));
  const [mobileViewInvestors, setMobileViewInvestors] = React.useState(false || pathname.includes('investors'));
  const [mobileViewInvestorsSublink1, setMobileViewInvestorsSublink1] = React.useState(false);
  const [mobileViewInvestorsSublink2, setMobileViewInvestorsSublink2] = React.useState(false);

  const drawer = (
    //  onClick={handleDrawerToggle} 
    <Box sx={{ textAlign: 'start', width: drawerWidth, padding: "none" }}>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { lg: 'block' }, color: "#ff385c", fontWeight: "bold", m: 2 }}
      >
        <img src={logo} width={150} alt="logo" />
      </Typography>
      <Divider />
      {/*    */}
      <List>
        {(mobileViewProducts === false && mobileViewInvestors === false) && 
          navItems.map((item, index) => (
          <div style={{ margin: '0px 20px'}} key={index}>
            <NavLink
              to={item.linkto !== "/product" && item.linkto}
              className={({ isActive }) =>
                ((item.linkto !== "/product" && item.linkto !== "/investors") && isActive) || ((pathname.includes("product") && item.title === "Products") || (pathname.includes("investors") && item.title === "Investors"))
                  ? 'nav_link active-link'
                  : 'nav_link'
              }
              onClick={() => {
                if (item.linkto === "/product") {
                  setMobileViewProducts(true);
                }
                if (item.linkto === "/investors") {
                  setMobileViewInvestors(true);
                }
              }}
            >
              {item.title}
            </NavLink>
          </div>
        ))}
        {(mobileViewProducts === true) && ( // If mobileViewProducts is true
          <div>
            <div className="dropdown" style={{ display: "flex", justifyContent: "start", alignItems:'center', margin: '0px 10px' }}>
              <button className="btn btn-none" onClick={() => setMobileViewProducts(false)}><i className="bi bi-arrow-left" style={{color:'white', fontSize:"16px"}}></i></button>
              <h4 className="nav_link active-link my-0" style={{textTransform:"uppercase"}}>Products</h4>
            </div>
            {ProductsLinks.map((subItem, i) => {
              return (
                <div style={{ margin: '0px 20px' }} key={i}>
                  <NavLink
                    to={subItem.linkto}
                    className={({ isActive }) =>
                      isActive ? 'nav_link active-link'
                        : 'nav_link'
                    }
                  >
                    {subItem.title}
                  </NavLink>
                </div>
              );
            })}
          </div>
        )}
        {(mobileViewInvestors === true && mobileViewInvestorsSublink1 === false && mobileViewInvestorsSublink2 === false) && ( // If mobileViewInvestors is true
          <div>
            <div className="dropdown" style={{ display: "flex", justifyContent: "start", alignItems:'center', margin: '0px 10px'  }}>
              <button className="btn btn-none" onClick={() => setMobileViewInvestors(false)}><i className="bi bi-arrow-left" style={{color:'white', fontSize:"16px"}}></i></button>
              <h4 className="nav_link active-link my-0" style={{textTransform:"uppercase"}}>Investors</h4>
            </div>
            {InvestorsLinks.map((subItem, i) => {
              return (
                <div style={{ margin: '0px 20px' }} key={i}>
                  <NavLink
                   to={(subItem.title !== "Initial Public Offer" && subItem.title !== "Announcements") && subItem.linkto}
                    className={({ isActive }) =>
                    ((subItem.title !== "Initial Public Offer" && subItem.title !== "Announcements") && isActive)
                        ? 'nav_link active-link'
                        : 'nav_link'
                    }
                    onClick={() => {
                      if (subItem.title === "Initial Public Offer") {
                        setMobileViewInvestorsSublink1(true);
                      }
                      if (subItem.title === "Announcements") {
                        setMobileViewInvestorsSublink2(true);
                      }
                    }}
                  >
                    {subItem.title}
                  </NavLink>
                </div>
              );
            })}
          </div>
        )}
        {(mobileViewInvestorsSublink1 === true) && (
          <div>
            <div className="dropdown" style={{ display: "flex", justifyContent: "start", alignItems:'center', margin: '0px 10px'  }}>
              <button className="btn btn-none" onClick={() => setMobileViewInvestorsSublink1(false)}><i className="bi bi-arrow-left" style={{color:'white', fontSize:"16px"}}></i></button>
              <h4 className="nav_link active-link my-0" style={{textTransform:"uppercase"}}>Initial Public Offer</h4>
            </div>
            {data.length > 0 && data.map((subItem, i) => {
              return (
                <div style={{ margin: '0px 20px' }} key={i}>
                  <NavLink
                    className='nav_link'
                    onClick={() => {
                      handleDownload("https://admin.balcopipes.in/" + subItem.offers_Document, `${subItem.document_Name}.pdf`)}
                    }
                  >
                    {subItem.document_Name}
                  </NavLink>
                </div>
              );
            })}
            <div className="w-100" style={{ margin: '0px 20px' }}>
              <NavLink
                className='nav_link'
                to={"/investors/material-documents"}
              >
                Material Documents
              </NavLink>
            </div>
          </div>
        )}
        {(mobileViewInvestorsSublink2 === true) && (
          <div>
            <div className="dropdown" style={{ display: "flex", justifyContent: "start", alignItems:'center', margin: '0px 10px'  }}>
              <button className="btn btn-none" onClick={() => setMobileViewInvestorsSublink2(false)}><i className="bi bi-arrow-left" style={{color:'white', fontSize:"16px"}}></i></button>
              <h4 className="nav_link active-link my-0" style={{textTransform:"uppercase"}}>Announcements</h4>
            </div>
            {Preissuedadvertisement.map((subItem, i) => {
              return (
                <div style={{ margin: '0px 20px' }} key={i}>
                  <NavLink
                    to={subItem.linkto}
                    className={({ isActive }) =>
                      isActive ? 'nav_link active-link'
                        : 'nav_link'
                    }
                  >
                    {subItem.title}
                  </NavLink>
                </div>
              );
            })}
          </div>
        )}
      </List>

    </Box>
  );

  return (
    <div style={{ padding: "none" }}>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ backgroundColor: "#004F87", color: "#fff", top: '0px', boxShadow: "none", position: isScrolled ? "fixed" : "relative" }}>
          <Toolbar sx={{ top: "0px", display: 'flex', justifyContent: "space-between" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { lg: 'block', color: "inherit", fontWeight: "bold" } }}
            >
              <img src={logo} width={150} alt="logo" />
            </Typography>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center', justifyContent: "center" }}>
              {navItems.map((item, index) => (
                <div style={{ margin: '0px 15px' }} key={index}>
                  <NavLink
                    to={item.linkto !== "/product" && item.linkto}
                    onClick={() => { item.linkto === "/product" && productToggleDropdown() }}
                    className={({ isActive }) =>
                      (item.linkto !== "/product" && isActive) || (pathname.includes("product") && item.title === "Products")
                        ? 'nav_link active-link'
                        : 'nav_link'
                    }
                  >
                    {
                      item.title === "Investors" ?
                        <div>
                          <div className="dropdown">
                            {item.title}<i onClick={toggleDropdown} className={`${isDropdownOpen ? "bi bi-arrow-up-square" : "bi bi-arrow-down-square"} ms-1`}></i>
                            <div id="myDropdown" className={isDropdownOpen ? 'dropdown-content d-block' : 'dropdown-content'}>
                              {InvestorsLinks.map((subItem, i) => {
                                return (
                                  <div className="w-100" key={i}>
                                    {subItem.title === "Initial Public Offer" ?
                                      <div>
                                        <div onClick={toggleSubDropdown}
                                          className={
                                            isSubDropdownOpen ? 'subnavbar active-subnavbar offer-dropdown' : 'subnavbar offer-dropdown'
                                          }
                                          style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                                          {subItem.title}
                                          <i className={`${isSubDropdownOpen ? "bi bi-arrow-left-square" : "bi bi-arrow-right-square"} ms-1`}></i>
                                        </div>
                                        {isSubDropdownOpen &&
                                          <div id="myDropdown" className={(isSubDropdownOpen) ? 'sub-dropdown-conten d-block' : 'sub-dropdown-conten'}>
                                            {data.length > 0 && data.map((offerItems, i) => {
                                              return (
                                                <div className="w-100" key={i}>
                                                  <NavLink
                                                    className='subnavbar'
                                                    onClick={() => { 
                                                      toggleSubDropdown(true);
                                                      handleDownload("https://admin.balcopipes.in" + offerItems.offers_Document, `${offerItems.document_Name}.pdf`)}
                                                    }
                                                    key={i}
                                                  >
                                                    {offerItems.document_Name}
                                                  </NavLink>
                                                </div>
                                              )
                                            })}
                                             <div className="w-100">
                                                  <NavLink
                                                    className='subnavbar'
                                                    onClick={() => { 
                                                      toggleSubDropdown(true);
                                                    }}
                                                    to={"/investors/material-documents"}
                                                  >
                                                    Material Documents
                                                  </NavLink>
                                                </div>
                                          </div>
                                        }
                                      </div>
                                      :
                                      subItem.title === "Announcements" ?
                                        <div>
                                          <div onClick={toggleadvertisementDropdown}
                                            className={
                                              isadvertisementDropdownOpen ? 'subnavbar active-subnavbar offer-dropdown' : 'subnavbar offer-dropdown'
                                            }
                                            style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                                            {subItem.title}
                                            <i className={`${isadvertisementDropdownOpen ? "bi bi-arrow-left-square" : "bi bi-arrow-right-square"} ms-1`}></i>
                                          </div>
                                          {isadvertisementDropdownOpen &&
                                            <div id="myDropdown" className={(isadvertisementDropdownOpen) ? 'advertisement-dropdown-conten d-block' : 'advertisement-dropdown-conten'}>
                                              {Preissuedadvertisement.map((offerItems, i) => {
                                                return (
                                                  <div className="w-100" key={i}>
                                                    <NavLink
                                                      to={offerItems.linkto}
                                                      className={({ isActive }) =>
                                                        isActive ? 'subnavbar active-subnavbar' : 'subnavbar'
                                                      }
                                                      onClick={() => {
                                                        toggleadvertisementDropdown(true);
                                                      }}
                                                      key={i}
                                                    >
                                                      {offerItems.title}
                                                    </NavLink>
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          }
                                        </div>
                                        :
                                        <NavLink
                                          to={subItem.linkto}
                                          className={({ isActive }) =>
                                            isActive ? 'subnavbar active-subnavbar' : 'subnavbar'
                                          }
                                        >
                                          {subItem.title}
                                        </NavLink>
                                    }
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        :
                        item.title === "Products" ?
                          <div>
                            <div className="dropdown">
                              {item.title}<i onClick={productToggleDropdown} className={`${isProductDropdownOpen ? "bi bi-arrow-up-square" : "bi bi-arrow-down-square"} ms-1`}></i>
                              <div id="myDropdown" className={isProductDropdownOpen ? 'dropdown-content d-block' : 'dropdown-content'}>
                                {ProductsLinks.map((subItem, i) => {
                                  return (
                                    <div className="w-100" key={i}>
                                      <NavLink
                                        to={subItem.linkto}
                                        className={({ isActive }) =>
                                          isActive ? 'subnavbar active-subnavbar' : 'subnavbar'
                                        }
                                        onClick={productToggleDropdown}
                                      >
                                        {subItem.title}
                                      </NavLink>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          :
                          item.title
                    }
                  </NavLink>
                </div>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: "end" }}>
              <Button sx={{ color: 'inherit', backgroundColor: '#004779', position: "relative", fontFamily: 'Gellix', textTransform: 'none' }} onClick={() => { navigate('/quote') }}>
                <span className="btn-square" style={{ top: "3px", left: "3px" }}></span> Get a Quote <i className="bi bi-arrow-up-right ms-2"></i>
              </Button>
              <Button sx={{ display: { md: 'flex', lg: 'none' } }} color="inherit" onClick={handleDrawerToggle}>
                <i className="bi bi-list"></i>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>


        <nav >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              paddingLeft: '0px',
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#004F87', paddingLeft: '0px' },
              '& .MuiDrawer': { paddingLeft: '0px' },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </div>
  );
}








// offerItems.title === "Pre issued advertisement" ?
//                                                 <div>
//                                                   <div
//                                                   onClick={toggleadvertisementDropdown}
//                                                   className={
//                                                     isSubDropdownOpen ? 'subnavbar active-subnavbar advertisement-dropdown' : 'subnavbar advertisement-dropdown'
//                                                   }
//                                                   style={{ display: "flex", justifyContent: "space-between", padding: "5px" }}
//                                                   >
//                                                     {offerItems.title}
//                                                     <i onClick={toggleadvertisementDropdown} className={`${isadvertisementDropdownOpen ? "bi bi-arrow-left-square" : "bi bi-arrow-right-square"} ms-1`}></i>
//                                                   </div>
//                                                   {isadvertisementDropdownOpen &&
//                                                     <div id="myDropdown" className={(isadvertisementDropdownOpen) ? 'advertisement-dropdown-conten d-block' : 'advertisement-dropdown-conten'}>
//                                                       {Preissuedadvertisement.map((offerItems, i) => {
//                                                         return (
//                                                           <NavLink
//                                                             to={offerItems.linkto}
//                                                             className={({ isActive }) =>
//                                                               isActive ? 'subnavbar active-subnavbar' : 'subnavbar'
//                                                             }
//                                                             key={i}
//                                                           >
//                                                             {offerItems.title}
//                                                           </NavLink>
//                                                         )
//                                                       })}
//                                                     </div>
//                                                   }
//                                                 </div>
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Components/home/HomePage';
import About from './Components/about/About';
import Dealer from './Components/dealer/Dealer';
import Investors from './Components/investors/Investors';
import Blog from './Components/blog/Blog';
import Career from './Components/career/Career';
import Contact from './Components/contact/Contact';
import Quote from './Components/quote/Quote';

import BoardOfDirectors from './Components/investors/BoardOfDirectors';
import Committee from './Components/investors/Committee';
import InvestorContact from './Components/investors/InvertorsContact';
import InvestorGrievances from './Components/investors/InvestorGrievances';
import Policies from './Components/investors/Policies';
import ShareHoldersPattern from './Components/investors/shareHoldersPattern';

import Nodata from './Components/investors/Nodata';

import PVCElectric from './Components/Products/PVCElectric';
import PVCElectricFittings from './Components/Products/PVCElectricFittings';
import PVCPipes from './Components/Products/PVCPipes';
import PVCWaterFitting from './Components/Products/PVCWaterFitting';
import Flexible from './Components/Products/Flexibility';
import Solvent from './Components/Products/SolventCement';
import GreenHoses from './Components/Products/GreenHoses';
import WaterTanks from './Components/Products/WaterTank';
import ErrorPage from './Components/Error_Page'; 
import PrivacyTerms from './Components/Privacy&Terms';
import TermsConditions from './Components/Terms&Conditions';
import MaterialDocuments from './Components/investors/materialDocuments';
import DisclosuresUnderRegulation from './Components/investors/disclosuresUnderRegulation';
import PressRelease from './Components/investors/pressRelease';
import AnnualReport from './Components/investors/annualReport';
import AnnualReturn from './Components/investors/annualReturn';
import FinancialResult from './Components/investors/financialResult';
import Gallery from './Components/gallery/GallleryCode';
import BoardMeeting from './Components/investors/Announcements/boardMeeting';
import GeneralMeeting from './Components/investors/Announcements/generalMeeting';
import Complainces from './Components/investors/Announcements/complainces';
import OtherAnnouncements from './Components/investors/Announcements/otherAnnouncements';



import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <div>
     <Router>
     <ScrollToTop />
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/product'>
              <Route index element={< PVCElectric />}></Route>
              <Route path='pvc-electricals' element={< PVCElectric />}></Route>
              <Route path='pvc-electricals-fitting' element={< PVCElectricFittings />}></Route>
              <Route path='pvc-water-pipes' element={< PVCPipes />}></Route>
              <Route path='pvc-water-pipes-fitting' element={< PVCWaterFitting />}></Route>
              <Route path='flexible-hoses' element={< Flexible />}></Route>
              <Route path='solvent' element={< Solvent />}></Route>
              <Route path='green-hoses' element={< GreenHoses />}></Route>
              <Route path='water-tanks' element={< WaterTanks />}></Route>
            </Route>
            <Route path='/dealer-networks' element={<Dealer/>} />
            <Route path='/investors'>
              <Route index element={< Investors />}></Route>
              <Route path='board-of-directors' element={< BoardOfDirectors />}></Route>
              <Route path='committee' element={< Committee />}></Route>
              <Route path='invertors-contact' element={< InvestorContact />}></Route>
              <Route path='investors-grievances' element={< InvestorGrievances />}></Route>
              <Route path='policies' element={< Policies />}></Route>
              <Route path='material-documents' element={< MaterialDocuments />}></Route>
              <Route path='disclosures-under-regulation' element={< DisclosuresUnderRegulation />}></Route>
              <Route path='press-release' element={< PressRelease />}></Route>
              <Route path='share-holders-pattern' element={< ShareHoldersPattern />}></Route>
              <Route path='annual-report' element={< AnnualReport />}></Route>
              <Route path='annual-return' element={< AnnualReturn />}></Route>
              <Route path='financial-result' element={< FinancialResult />}></Route>
              <Route path='board-meeting' element={< BoardMeeting />}></Route>
              <Route path='general-meeting' element={< GeneralMeeting />}></Route>
              <Route path='complainces' element={< Complainces />}></Route>
              <Route path='other-announcements' element={< OtherAnnouncements />}></Route>
              <Route path='no-data' element={< Nodata />}></Route>
            </Route>
            <Route path='/blog' element={<Blog/>} />
            <Route path='/gallery' element={<Gallery/>} />
            <Route path='/career' element={<Career/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/quote' element={<Quote/>} />
            <Route path='/privacy&terms' element={<PrivacyTerms/>} />
            <Route path='/terms&conditions' element={<TermsConditions/>} />
            {/* <Route path='*' element={<ErrorPage />}></Route> */}
          </Routes>
      </Router>
    </div>
  );
}

export default App;

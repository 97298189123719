import image1 from '../../assets/Gallery/image 1.png';
import image2 from '../../assets/Gallery/image 2.png';
import image3 from '../../assets/Gallery/image 3.png';
import image4 from '../../assets/Gallery/image 4.png';
import image5 from '../../assets/Gallery/image 5.png';
import image6 from '../../assets/Gallery/image 6.png';
import image7 from '../../assets/Gallery/image 7.png';
import image8 from '../../assets/Gallery/image 8.png';
import image9 from '../../assets/Gallery/image 9.png';
import image10 from '../../assets/Gallery/image 10.png';
import image11 from '../../assets/Gallery/image 11.png';
import image12 from '../../assets/Gallery/image 12.png';
import image13 from '../../assets/Gallery/image 13.png';
import image14 from '../../assets/Gallery/image 14.png';
import image15 from '../../assets/Gallery/image 15.png';
import image16 from '../../assets/Gallery/image 16.png';
import image17 from '../../assets/Gallery/image 17.png';
import image18 from '../../assets/Gallery/image 18.png';

import image19 from '../../assets/Gallery/Products 2-01.png';
import image20 from '../../assets/Gallery/Products 2-02.png';
import image21 from '../../assets/Gallery/Products 2-03.png';
import image22 from '../../assets/Gallery/Products 2-04.png';
import image23 from '../../assets/Gallery/Products 2-05.png';
import image24 from '../../assets/Gallery/Products 2-06.png';
import image25 from '../../assets/Gallery/Products 2-07.png';
import image26 from '../../assets/Gallery/Products 2-08.png';
import image27 from '../../assets/Gallery/Products 2-09.png';
import image28 from '../../assets/Gallery/Products 2-10.png';
import image29 from '../../assets/Gallery/Products 2-11.png';
import image30 from '../../assets/Gallery/Products 2-12.png';
import image31 from '../../assets/Gallery/Products 2-13.png';
import image32 from '../../assets/Gallery/Products 2-14.png';
import image33 from '../../assets/Gallery/Products 2-15.png';
import image34 from '../../assets/Gallery/Products 2-16.png';
import image35 from '../../assets/Gallery/Products 2-17.png';
import image36 from '../../assets/Gallery/Products 2-18.png';
import image37 from '../../assets/Gallery/Products 2-19.png';
import image38 from '../../assets/Gallery/Products 2-20.png';
import image39 from '../../assets/Gallery/Products 2-21.png';
import image40 from '../../assets/Gallery/Products 2-22.png';
import image41 from '../../assets/Gallery/Products 2-23.png';
import image42 from '../../assets/Gallery/Products 2-24.png';
import image43 from '../../assets/Gallery/Products 2-25.png';
import image44 from '../../assets/Gallery/Products 2-26.png';
import image45 from '../../assets/Gallery/Products 2-27.png';
import image46 from '../../assets/Gallery/Products 2-28.png';
import image47 from '../../assets/Gallery/Products 2-29.png';
import image48 from '../../assets/Gallery/Products 2-30.png';
import image49 from '../../assets/Gallery/Products 2-31.png';
import image50 from '../../assets/Gallery/Products 2-32.png';
import image51 from '../../assets/Gallery/Products 2-33.png';
import image52 from '../../assets/Gallery/Products 2-34.png';
import image53 from '../../assets/Gallery/Products 2-35.png';
import image54 from '../../assets/Gallery/Products 2-36.png';
import image55 from '../../assets/Gallery/Products 2-37.png';
import image56 from '../../assets/Gallery/Products 2-38.png';
import image57 from '../../assets/Gallery/Products 2-39.png';
import image58 from '../../assets/Gallery/Products 2-40.png';
import image59 from '../../assets/Gallery/Products 2-41.png';
import image60 from '../../assets/Gallery/Products 2-42.png';
import image61 from '../../assets/Gallery/Products 2-43.png';
import image62 from '../../assets/Gallery/Products 2-44.png';
import image63 from '../../assets/Gallery/Products 2-45.png';
import image64 from '../../assets/Gallery/Products 2-46.png';
import image65 from '../../assets/Gallery/Products 2-47.png';
import image66 from '../../assets/Gallery/Products 2-48.png';
import image67 from '../../assets/Gallery/Products 2-49.png';
import image68 from '../../assets/Gallery/Products 2-50.png';
import image69 from '../../assets/Gallery/Products 2-51.png';
import image70 from '../../assets/Gallery/Products 2-52.png';
import image71 from '../../assets/Gallery/Products 2-53.png';
import image72 from '../../assets/Gallery/Products 2-54.png';
import image73 from '../../assets/Gallery/Products 2-55.png';
import image74 from '../../assets/Gallery/Products 2-56.png';
import image75 from '../../assets/Gallery/Products 2-57.png';
import image76 from '../../assets/Gallery/Products 2-58.png';
import image77 from '../../assets/Gallery/Products 2-59.png';

const images = [
  image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
  image11, image12, image13, image14, image15, image16, image17, image18,
  image19, image20, image21, image22, image23, image24, image25, image26, image27, image28,
  image29, image30, image31, image32, image33, image34, image35, image36, image37, image38,
  image39, image40, image41, image42, image43, image44, image45, image46, image47, image48,
  image49, image50, image51, image52, image53, image54, image55, image56, image57, image58,
  image59, image60, image61, image62, image63, image64, image65, image66, image67, image68,
  image69, image70, image71, image72, image73, image74, image75, image76, image77
];

export default images;
